<div class="px-3">
    <div class="theme-container">  
        <div class="get-in-touch bg-primary" ngClass.lt-md="mt-3"> 
            <img src="assets/images/others/operator.png" alt="operator" fxShow="false" fxShow.gt-xs> 
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="content" ngClass.xs="p-0"> 
                <div fxLayout="column" class="py-2 px-3">
                    <h1 class="secondary-font">Looking for fresh produce?</h1>
                    <p class="mb-0">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="py-2 px-3">
                    <mat-icon class="mat-icon-xlg mx-2">call</mat-icon>
                    <div fxLayout="column">               
                        <p class="mb-0">CALL US NOW</p>
                        <h2 class="ws-nowrap">(+100) 123 456 7890</h2>
                    </div>
                </div>
                <div class="py-2 px-3">
                    <a mat-flat-button routerLink="/contact" class="mx-3 uppercase">get in touch</a>
                </div>
            </div>  
        </div> 
    </div>
</div>